import React from 'react'
import ReactDOM from 'react-dom'
import './css/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { sendToVercelAnalytics } from './vitals'
import 'mapbox-gl/dist/mapbox-gl.css'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import FeedProvider from './contexts/feedContext'
import SecuredApp from './SecuredApp'

const router = createBrowserRouter([
    {
        path: '/clicks-and-clients',
        element: (
            <SecuredApp>
                <FeedProvider app="clicks-and-clients">
                    <App version="clicks-and-clients" />
                </FeedProvider>
            </SecuredApp>
        ),
    },
    {
        path: '/sweetgreen',
        element: (
            <SecuredApp>
                <FeedProvider app="sweetgreen">
                    <App version="sweetgreen" />
                </FeedProvider>
            </SecuredApp>
        ),
    },
    {
        path: '/john-elliott',
        element: (
            <SecuredApp>
                <FeedProvider app="john-elliott">
                    <App version="john-elliott" />
                </FeedProvider>
            </SecuredApp>
        ),
    },
    {
        path: '/evolve-together',
        element: (
            <SecuredApp>
                <FeedProvider app="evolve-together">
                    <App version="evolve-together" />
                </FeedProvider>
            </SecuredApp>
        ),
    },
    {
        path: '/brightfin',
        element: (
            <SecuredApp>
                <FeedProvider app="brightfin">
                    <App version="brightfin" />
                </FeedProvider>
            </SecuredApp>
        ),
    },
    {
        path: '/stjohnknits',
        element: (
            <SecuredApp>
                <FeedProvider app="stjohnknits">
                    <App version="stjohnknits" />
                </FeedProvider>
            </SecuredApp>
        ),
    },
    {
        path: '/stjohnknits',
        element: (
            <SecuredApp>
                <FeedProvider app="stjohnknits">
                    <App version="stjohnknits" />
                </FeedProvider>
            </SecuredApp>
        ),
    },
    {
        path: '/lanvin',
        element: (
            <SecuredApp>
                <FeedProvider app="lanvin">
                    <App version="lanvin" />
                </FeedProvider>
            </SecuredApp>
        ),
    },
    {
        path: '/sergio-rossi',
        element: (
            <SecuredApp>
                <FeedProvider app="sergio-rossi">
                    <App version="sergio-rossi" />
                </FeedProvider>
            </SecuredApp>
        ),
    },
    {
        path: '/amnh',
        element: (
            <SecuredApp>
                <FeedProvider app="amnh">
                    <App version="amnh" />
                </FeedProvider>
            </SecuredApp>
        ),
    },
    {
        path: '/blue-raven',
        element: (
            <SecuredApp>
                <FeedProvider app="blue-raven">
                    <App version="blue-raven" />
                </FeedProvider>
            </SecuredApp>
        ),    
    },
    {
        path: '/chinah',
        element: (
            <SecuredApp>
                <FeedProvider app="chinah">
                    <App version="chinah" />
                </FeedProvider>
            </SecuredApp>
        ),
    },
    {
        path: '/hello-prenup',
        element: (
            <SecuredApp>
                <FeedProvider app="hello-prenup">
                    <App version="hello-prenup" />
                </FeedProvider>
            </SecuredApp>
        ),
    },
    {
        path: '/ivy-rehab',
        element: (
            <SecuredApp>
                <FeedProvider app="ivy-rehab">
                    <App version="ivy-rehab" />
                </FeedProvider>
            </SecuredApp>
        ),
    },
    {
        path: '/justsalad',
        element: (
            <SecuredApp>
                <FeedProvider app="justsalad">
                    <App version="justsalad" />
                </FeedProvider>
            </SecuredApp>
        ),
    },
    {
        path: '/moonjuice',
        element: (
            <SecuredApp>
                <FeedProvider app="moonjuice">
                    <App version="moonjuice" />
                </FeedProvider>
            </SecuredApp>
        ),
    },
    {
        path: '/prime-storage',
        element: (
            <SecuredApp>
                <FeedProvider app="prime-storage">
                    <App version="prime-storage" />
                </FeedProvider>
            </SecuredApp>
        ),
    },
    {
        path: '/schwarzman-scholars',
        element: (
            <SecuredApp>
                <FeedProvider app="schwarzman-scholars">
                    <App version="schwarzman-scholars" />
                </FeedProvider>
            </SecuredApp>
        ),
    },            
    {
        path: '/uprise-health',
        element: (
            <SecuredApp>
                <FeedProvider app="uprise-health">
                    <App version="uprise-health" />
                </FeedProvider>
            </SecuredApp>
        ),
    },
    {
        path: '/sjk',
        element: (
            <SecuredApp>
                <FeedProvider app="stjohnknits">
                    <App version="stjohnknits"/>
                </FeedProvider>
            </SecuredApp>
        ),
    },
    {
        path: '/corraldata',
        element: (
            <SecuredApp>
                <FeedProvider app="corraldata">
                    <App version="corraldata"/>
                </FeedProvider>
            </SecuredApp>
        ),
    },
    {
        path: '/kearnypoint',
        element: (
            <SecuredApp>
                <FeedProvider app="kearnypoint">
                    <App version="kearnypoint"/>
                </FeedProvider>
            </SecuredApp>
        ),
    },
    {
        path: '/',
        element: (
           <h1>CorralData GPT</h1>
        ),
    }
])

ReactDOM.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>,
    document.getElementById('root')
)

reportWebVitals(sendToVercelAnalytics)
