import { createContext, useState, useEffect } from 'react'

import keycloak from "./Keycloak"

export const SecuredAppContext = createContext()

const SecuredApp = ({ app, children }) => {
    const [token, setToken] = useState(null)

    useEffect(() => {
        console.log('keycloak init')
        keycloak.init({ onLoad: 'login-required', flow: 'implicit'}).success((authenticated) => {
            if (authenticated) {
              console.log('User is authenticated');
              setToken(keycloak.token);
            } else {
              console.log('User is not authenticated');
            }
        });
    }, [])

    return (
        <SecuredAppContext.Provider value={{ token }}>
            {children}
        </SecuredAppContext.Provider>
    )
}

export default SecuredApp
