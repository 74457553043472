import { useEffect, useState } from 'react'
import { XCircleIcon } from '@heroicons/react/20/solid'

export const ExplanationModal = ({showExplanationModal, setShowExplanationModal, version}) =>{

    const messageToShow = showExplanationModal == 'no_tables' ? "Sorry, we don't think we're able to help with that query yet ='(" : "Sorry, we tried to answer your question but weren't able to get a working query."

    return (

    <div className={`rounded-md bg-red-50 p-4`}>
        <div className="flex">
            <div className="flex-shrink-0">
                <XCircleIcon
                    className="h-5 w-5 text-red-400 cursor-pointer"
                    aria-hidden="true"
                    onClick={
                        () => {
                            setShowExplanationModal(false)
                        }
                    }
                />
            </div>
            <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                    <div>{messageToShow}</div>
                </h3>
            </div>
        </div>
    </div>
    )    

}
const Disclaimer = (props) => {
    const GptDisclaimer = (
        <>
        </>
    );

    return (
        <div className="text-sm text-gray-600 dark:text-white font-medium mx-auto">
            {GptDisclaimer}
        </div>
    )
}

export default Disclaimer
